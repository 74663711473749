import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Spacer } from "ui-start";
import { HeroImage } from "../components/heroImage";
import Layout from "../components/layout";

import { StyledPageCopy, Wrapper } from "../components/styles";
import { VolunteerTestimonials } from "../components/volunteerTestimonials";

const Volunteer = () => {
  const data = useStaticQuery(graphql`
    query VolunteerPageQuery {
      allGoogleSheet {
        edges {
          node {
            volunteer {
              copy01
            }
          }
        }
      }
    }
  `);

  const volunteerData = data?.allGoogleSheet?.edges?.[0]?.node?.volunteer ?? [];
  const pageData = volunteerData[0] ?? null;

  return (
    <Layout>
      <HeroImage page="volunteer" />
      <Wrapper>
        <Spacer height={32} />
        <StyledPageCopy
          dangerouslySetInnerHTML={{ __html: pageData?.copy01 }}
        />
        <VolunteerTestimonials />
      </Wrapper>
    </Layout>
  );
};

export default Volunteer;
